import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Why = () => {
  const data = [
    {
      icon: require("assets/images/clients/clients-management-why-icon-1.svg")
        .default,
      header: "Gotowe projekty wnętrz i wykończenia",
    },
    {
      icon: require("assets/images/clients/clients-management-why-icon-2.svg")
        .default,
      header: "Obsługa wynajmu krótkoterminowego",
    },
    {
      icon: require("assets/images/clients/clients-management-why-icon-3.svg")
        .default,
      header: "Obsługa wynajmu  długoterminowego",
    },
  ]

  return (
    <section className="clients-management-why">
      <div className="container-fluid">
        <h2>
          Podobnie jak 75% naszych Klientów, zdecyduj się na bezobsługową
          inwestycję:
        </h2>
        <div className="row justify-content-center">
          {data.map((item, index) => (
            <div className="col-md-4 col-6" key={index}>
              <div className="clients-management-why__item">
                <div className="clients-management-why__item-icon">
                  <LazyFadeImage>
                    <img src={item.icon} alt="" />
                  </LazyFadeImage>
                </div>
                <h4>{item.header}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Why
