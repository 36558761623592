import "./styles.scss"

import React from "react"
import Slider from "react-slick"

import LazyFadeImage from "components/LazyFadeImage"

const Gallery = () => {
  const slider = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
  }

  const data = [
    require("assets/images/clients/clients-management-gallery-image-1.jpg")
      .default,
    require("assets/images/clients/clients-management-gallery-image-2.jpg")
      .default,
    require("assets/images/clients/clients-management-gallery-image-3.jpg")
      .default,
    require("assets/images/clients/clients-management-gallery-image-4.jpg")
      .default,
  ]

  return (
    <section className="clients-management-gallery">
      <div className="container-fluid">
        <h2>Projekty wnętrz i wykończenia</h2>
        <Slider {...slider} className="slick-slider--arrows-big">
          {data.map((item, index) => (
            <div key={index}>
              <LazyFadeImage>
                <div
                  className="clients-management-gallery__slide"
                  style={{
                    backgroundImage: `url('${item}')`,
                  }}
                ></div>
              </LazyFadeImage>
              <p>
                *Przedstawione wizualizacje ukazują przykładowe wykończenie i
                wyposażenie wnętrz w inwestycjach z oferty Marshall Real Estate
              </p>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default Gallery
