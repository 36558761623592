import "./styles.scss"

import React from "react"

const PageHeader = ({ title, narrow }) => {
  return (
    <section className={`page-header${narrow ? " page-header--narrow" : ""}`}>
      <div className="container-fluid">
        <h1>{title}</h1>
      </div>
    </section>
  )
}

export default PageHeader
