import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Solutions = () => {
  return (
    <section className="clients-management-solutions">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-4 offset-xl-1 col-md-6">
            <h2>Inteligentne rozwiązania</h2>
            <p>
              Poza standardowymi rozwiązaniami (takimi jak aranżacja wnętrz czy
              promocja obiektu), operatorzy coraz częściej oferują również m.in.
              autorskie systemy IT umożliwiające śledzenie danych finansowych,
              obsługi rezerwacji i housekeeping'u w czasie rzeczywistym,
              monitorowanie szkód i szybką reakcję na pojawiające się problemy.
              Dodatkowo, dla gości apartamentów przygotowane zostały serwisy
              typu usługa e-concierge oraz klub lojalnościowy z możliwością
              rezerwacji w miejscach partnerskich poprzez aplikację.
            </p>
          </div>
          <div className="col-xl-6 offset-xl-1 col-md-6">
            <LazyFadeImage>
              <img
                src={
                  require("assets/images/clients/clients-management-solutions-image.jpg")
                    .default
                }
                alt=""
              />
            </LazyFadeImage>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Solutions
