import "./styles.scss"

import React from "react"

const Tiles = () => {
  return (
    <section className="clients-management-tiles">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-4 col-lg-5 offset-lg-1 col-sm-6">
            <div className="clients-management-tiles__item clients-management-tiles__item--border">
              <div className="clients-management-tiles__item-image">
                <img
                  src={
                    require("assets/images/clients/clients-management-tiles-icon-1.svg")
                      .default
                  }
                  alt=""
                />
                <h3>Dla właścicieli</h3>
                <ul>
                  <li>- monitoring obłożenia,</li>
                  <li>- monitoring cen,</li>
                  <li>- panel statystyk,</li>
                  <li>- monitoring serwisu Loft,</li>
                  <li>- szczegóły każdej rezerwacji,</li>
                  <li>- podgląd to każdej aktualizacji.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-5 offset-lg-1 col-sm-6">
            <div className="clients-management-tiles__item">
              <div className="clients-management-tiles__item-image">
                <img
                  src={
                    require("assets/images/clients/clients-management-tiles-icon-2.svg")
                      .default
                  }
                  alt=""
                />
                <h3>Dla klienta docelowego</h3>
                <ul>
                  <li>- dostęp do lokalnych atrakcji,</li>
                  <li>- czat z e-concierge,</li>
                  <li>- mobilne meldowanie,</li>
                  <li>- zarządzanie rezerwacją.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Tiles
