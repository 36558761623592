import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Grid = () => {
  return (
    <section className="clients-management-grid">
      <div className="container-fluid">
        <h2>
          Dlaczego warto skorzystać z kompleksowej usługi wykończenia
          apartamentu pod klucz oraz zarządzania najmem?
        </h2>
        <div className="row align-items-center">
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <h4>Oszczędność czasu</h4>
            <p>
              Wynajmując nieruchomość po raz pierwszy lub samodzielnie
              zarządzając kilkoma apartamentami należy poświecić czas na
              poszukiwanie najemców czy żmudne przygotowania nieruchomości do
              wynajmu. Jest się również odpowiedzialnym za dokonywanie drobnych
              napraw czy rozliczanie zużycia mediów.
            </p>
            <h4>Nadzór nad pracami remontowymi</h4>
            <p>
              Przygotowanie apartamentu pod klucz wiąże się z dużym stresem.
              Trudne jest dopilnowanie prac wykończeniowych nie będąc na co
              dzień w miejscowości, w której zakupiono nieruchomość.
            </p>
            <h4>Zwiększenie konkurencyjności</h4>
            <p>
              Zyskujesz kompleksową obsługę całego procesu co umożliwia
              sprawniejsze przystosowanie apartamentu do potrzeb najmu w sposób
              bardziej profesjonalny z uwzględnieniem rynkowych trendów czy
              nowoczesnych technologii.
            </p>
          </div>
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <LazyFadeImage>
              <div
                className="clients-management-grid__image"
                style={{
                  backgroundImage: `url('${
                    require("assets/images/clients/clients-management-grid-image-1.jpg")
                      .default
                  }')`,
                }}
              />
            </LazyFadeImage>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-xl-5 col-md-6">
            <LazyFadeImage>
              <div
                className="clients-management-grid__image clients-management-grid__image--2"
                style={{
                  backgroundImage: `url('${
                    require("assets/images/clients/clients-management-grid-image-2.jpg")
                      .default
                  }')`,
                }}
              />
            </LazyFadeImage>
          </div>
          <div className="col-xl-5 offset-xl-2 col-md-6">
            <h4>Świeże, profesjonalne spojrzenie na biznes</h4>
            <p>
              Zespół ekspertów łączy pasję do nowych technologii, marketingu,
              finansów i turystyki stosując innowacyjne rozwiązania w dziedzinie
              zarządzania.
            </p>
            <h4>Zachowanie płynności finansowej inwestycji</h4>
            <p>
              Stały kontakt z najemcą, opracowane umowy najmu czy kontrola nad
              przestrzeganiem zasad panujących w obiekcie gwarantują dłuższy
              wynajem i zachowanie jego ciągłości
            </p>
            <h4>Przychody oparte na wynikach</h4>
            <p>Najbardziej popularny model cenowy, bez konfliktu interesów.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
