import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Hero = () => {
  return (
    <section className="clients-management-hero">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-5 col-md-6">
            <h2>Wykorzystaj potencjał swojej nieruchomości</h2>
            <p>
              Nasi partnerzy wiedzą, jak zmaksymalizować Twój zysk pochodzący z
              najmu. Postaw na bezobsługową inwestycję dzięki możliwości odbioru
              wykończonego i wyposażonego lokalu. Jesteś zainteresowany
              czerpaniem zysków z najmu? Zaufaj rekomendowanym, profesjonalnym
              operatorem.
            </p>
            <p>
              Prace budowlane czy remontowe nie muszą kojarzyć się już z dużym
              stresem, a wybór mebli i dekoracji będzie przyjemnością. Tak
              precyzyjnie przygotowana nieruchomość stanie się niemalże
              produktem szytym na miarę oczekiwań gości wypoczywających w Twoim
              apartamencie. Z czasem, stanie się również ich pierwszym wyborem.
            </p>
          </div>
          <div className="col-xl-6 offset-xl-1 col-md-6">
            <LazyFadeImage>
              <div
                className="clients-management-hero__image"
                style={{
                  backgroundImage: `url('${
                    require("assets/images/clients/clients-management-hero-image.jpg")
                      .default
                  }')`,
                }}
              />
            </LazyFadeImage>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
