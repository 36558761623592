import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import {
  Hero,
  Why,
  Gallery,
  Partners,
  Grid,
  Services,
  Revenue,
  Solutions,
  Tiles,
} from "page_components/clients-management"

const Developers = () => {
  const title = "Zarządzanie apartamentem i wykończenie wnętrz"

  return (
    <Layout
      seo={{
        title: title,
        description:
          "Klientom Marshall Real Estate dajemy możliwość powierzenia swojego apartamentu w ręce profesjonalistów odpowiedzialnych za maksymalizację zysków pochodzących z najmu.",
      }}
    >
      <Breadcrumbs
        title={title}
        parent={{
          title: "Dla klientów",
          path: "/dla-klientow/",
        }}
      />
      <PageHeader title={title} narrow />
      <Hero />
      <Why />
      <Gallery />
      <Partners />
      <Grid />
      <Revenue />
      <Services />
      <Solutions />
      <Tiles />
    </Layout>
  )
}

export default Developers
