import "./styles.scss"

import React from "react"

import LazyFadeImage from "components/LazyFadeImage"

const Partners = () => {
  return (
    <section className="clients-management-partners">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-5 col-md-6">
            <LazyFadeImage>
              <div
                className="clients-management-partners__image"
                style={{
                  backgroundImage: `url('${
                    require("assets/images/clients/clients-management-partners-image.jpg")
                      .default
                  }')`,
                }}
              />
            </LazyFadeImage>
          </div>
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <h2>Poznaj naszych partnerów</h2>
            <p>
              Współpracujemy z doświadczonymi operatorami realizującymi
              kompleksowe usługi w zakresie wykończenia i wyposażenia
              apartamentu oraz zarządzania nieruchomościami premium. Naszym
              klientom oferujemy możliwość maksymalizacji zysków przy ich
              minimalnym zaangażowaniu.
            </p>
            <p>
              Nasi partnerzy służą wsparciem już na etapie projektowania
              apartamentu. Odpowiadają za przygotowanie propozycji aranżacji
              wnętrz, czuwają nad przebiegiem prac budowlanych i remontowych.
              Świadczą usługi w zakresie Home staging, by przygotowany
              apartament spełniał oczekiwania przyszłych gości, a następnie
              odpowiadają za profesjonalną obsługę najmu.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Partners
