import "./styles.scss"

import React from "react"

const Revenue = () => {
  return (
    <section className="clients-management-revenue">
      <div className="container-fluid">
        <div className="row g-0">
          <div className="col-md-6">
            <div className="clients-management-revenue__wrapper">
              <h2>Przychody oparte na wynikach</h2>
              <p>
                To nasz najbardziej popularny model biznesowy, w którym zarówno
                właścicielom jak i operatorowi zależy na maksymalizacji
                przychodów pochodzących z najmu.
              </p>
              <p>
                70% zysku netto z rezerwacji dla właściciela. Zysk netto to
                całkowity przychód z wynajmu po odjęciu VAT i prowizji
                zewnętrznych portali. 0% opłat dodatkowych pobieranych od
                właścicieli, media po stronie właściciela
              </p>
              <small>
                *Poziom podziału zysku może się różnić w zależności od wybranej
                firmy zarządzającej czy indywidualnej oferty.
              </small>
            </div>
          </div>

          <div className="col-md-6">
            <div className="clients-management-revenue__inner">
              <img
                src={
                  require("assets/images/clients/clients-management-revenue-icon.svg")
                    .default
                }
                alt=""
              />
              <span>
                70% <br />
                zysku netto
              </span>
              <h3>z rezerwacji dla właściciela.*</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Revenue
