import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

const Breadcrumbs = ({ title, parent, subParent, subSubParent }) => {
  return (
    <section className="breadcrumbs">
      <div className="container-fluid">
        <div className="breadcrumbs__wrapper">
          <Link to="/">Strona główna</Link>
          {subSubParent && (
            <>
              <span>&gt;</span>
              <Link to={subSubParent.path}>{subSubParent.title}</Link>
            </>
          )}
          {subParent && (
            <>
              <span>&gt;</span>
              <Link to={subParent.path}>{subParent.title}</Link>
            </>
          )}
          {parent && (
            <>
              <span>&gt;</span>
              <Link to={parent.path}>{parent.title}</Link>
            </>
          )}
          <span>&gt;</span>
          <p>{title}</p>
        </div>
      </div>
    </section>
  )
}

export default Breadcrumbs
